import {
	filterCategoriesByTypes,
	filterTypesByCode,
	types,
} from './categories';
import { APPLICATION_MODULES, PRODUCT_FAMILIES } from './constants';

function getAllModules() {
	return ['all', ...Object.values(APPLICATION_MODULES)];
}

function getAllStrategies() {
	const strategiesType = ['all'];

	Object.keys(types).forEach(key => {
		types[key].forEach(type => strategiesType.push(type.code));
	});

	return strategiesType;
}

export function getFormattedSignatures(userSignatures = []) {
	return userSignatures.map(signature => {
		let formattedSignature = { ...signature };

		if (formattedSignature.product.attributes.modules.includes('all')) {
			formattedSignature.product.attributes.modules = getAllModules();
		}

		if (formattedSignature.product.attributes.strategies.includes('all')) {
			formattedSignature.product.attributes.strategies =
				getAllStrategies();
		}

		if (formattedSignature.product.isPartnerProduct) {
			formattedSignature.product.attributes.modules = [
				...formattedSignature.product.attributes.modules,
				'external-product',
			];
		}

		return formattedSignature;
	});
}

export function getPermittedBullets(userSignatures = []) {
	const permittedStrategies = [];

	userSignatures.forEach(signature => {
		permittedStrategies.push(...signature.product.attributes.strategies);
	});

	const permittedStrategyCodes = [...new Set(permittedStrategies)];

	const permittedBulletTypes = filterTypesByCode(permittedStrategyCodes);

	const permittedBulletCategories =
		filterCategoriesByTypes(permittedBulletTypes);

	const permittedBullets = {
		permittedBulletTypes,
		permittedBulletCategories,
		permittedStrategyCodes,
	};

	return permittedBullets;
}

export function getActiveProduct(userSignatures = [], productPath = null) {
	let activeProduct;

	if (productPath) {
		return userSignatures.find(
			signature =>
				signature.status &&
				signature.product.code === productPath.toUpperCase()
		)?.product;
	}

	activeProduct = userSignatures.find(
		signature =>
			signature.status &&
			signature.product.family === PRODUCT_FAMILIES.FLEXSCAN
	)?.product;

	if (!activeProduct) {
		activeProduct = userSignatures.find(
			signature => signature.status
		)?.product;
	}

	if (activeProduct) {
		return activeProduct;
	}

	return null;
}
