import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Grid, Button, TextField, IconButton, Typography } from '@mui/material';
import { HighlightOff, ContentPasteGo, Settings } from '@mui/icons-material';

import { getCategoryFromStrategyCode } from '../../utils/strategies';
import {
	additionalUpdate,
	strategyUpdate,
} from 'store/modules/bottomBullet/actions';

import { BulletSelect } from './BulletSelect';
import { LightTooltip } from 'components/LightTooltip';
import {
	setGlobalSettingsModule,
	setIsGlobalSettingsOpen,
} from 'store/modules/panelConfig/actions';
import { USER_TYPES } from 'utils/constants';
import { categories as allBulletCategories } from 'utils/categories';

export const Header = ({
	bullet,
	isListsOpen,
	isSavedListVisible,
	isRecentListVisible,
	openLists,
	openBullet,
	closeBullet,
	handleBullet,
	createBulletToPaste,
}) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const { accounts, userType, username } = useSelector(state => state.auth);
	const { defaultAccount } = useSelector(
		state => state.configs.globalSettings?.userSettings?.bullet
	);
	const { permittedBulletTypes, permittedBulletCategories } = useSelector(
		state => state.products.permittedBullets
	);

	const selectedCategory = getCategoryFromStrategyCode(
		bullet.content.StrategyCode
	);

	const bulletsWithoutType = ![3, 4, 7, 8].includes(selectedCategory);

	function handleCategory(value) {
		dispatch(additionalUpdate('category', value));
		const subUidInterceptor = value === 5 ? 2 : 1;
		handleBullet('subUid', subUidInterceptor, true, value);
	}

	const toggleButtonLabel = bullet.isOpen
		? 'bullets.general.toggle_bullet_close'
		: 'bullets.general.toggle_bullet_open';

	function getCategorySelectOptions(categories) {
		if (
			userType.id === USER_TYPES.MESA ||
			[
				'flx_fschiller',
				'ora_felipe40865',
				'fut_ut01',
				'ora_sergmf',
				'ora_oraloe',
				'ora_flavio13730',
				'ora_jose26400',
			].includes(username)
		) {
			return allBulletCategories.map(category => ({
				value: category.id,
				label: category.name,
			}));
		}

		return categories.map(category => ({
			value: category.id,
			label: category.name,
		}));
	}

	function changeStrategyNameText(value) {
		const order = { ...bullet.content };
		order.Name = value.replace(/[^\w-]/g, '');
		dispatch(strategyUpdate(order));
	}

	function handleAccount(value) {
		let finalValue = value;

		if ([USER_TYPES.MESA, USER_TYPES.ASSESSOR].includes(userType.id)) {
			finalValue = value.trim().substring(0, 11);
		}

		dispatch(additionalUpdate('account', finalValue));
	}

	function getAccountOptions() {
		function formatAccountLabel(code) {
			if (username.startsWith('btg_')) {
				if (code.startsWith('D-')) {
					return `DayTrade (${code})`;
				}

				if (!isNaN(Number(code))) {
					return `Posição (${code})`;
				}
			}

			return code;
		}

		const seenCodes = new Set();

		const options = accounts
			.filter(accountItem => {
				if (seenCodes.has(accountItem.code)) {
					return false;
				}

				seenCodes.add(accountItem.code);
				return true;
			})
			.map(accountItem => ({
				value: accountItem.code,
				label: formatAccountLabel(String(accountItem.code)),
			}));

		if (options.length > 1) {
			options.unshift({
				value: 'Selecione',
				label: 'Selecione',
			});
		}

		return options;
	}

	function toggleBullet() {
		bullet.isOpen ? closeBullet(true) : openBullet();
	}

	return (
		<>
			<Grid
				item
				container
				bgcolor="#303030"
				sx={{ px: 1.5, pt: 2, pb: 1, borderTop: '1px solid #eeeeeeb3' }}
			>
				<Grid
					xs={5}
					item
					container
					spacing={1}
					sx={{ alignItems: 'center' }}
				>
					{!isListsOpen && (
						<>
							<Grid item>
								<Typography
									variant="subtitle1"
									textAlign="center"
								>
									{t('bullets.general.label')}
								</Typography>
							</Grid>

							<Grid item xs={3}>
								<BulletSelect
									defaultValue={1}
									label="Estratégia"
									key="strategySelect"
									name="strategySelect"
									value={selectedCategory}
									options={
										getCategorySelectOptions(
											permittedBulletCategories
										) ?? []
									}
									disabled={
										isListsOpen || bullet.content.ClOrdID
									}
									onChange={event =>
										handleCategory(
											Number(event.target.value)
										)
									}
								/>
							</Grid>

							{bulletsWithoutType && (
								<Grid item xs={3}>
									<BulletSelect
										defaultValue={1}
										label="Tipo"
										key="typeSelect"
										name="typeSelect"
										value={bullet.subUid ?? 1}
										disabled={
											isListsOpen ||
											bullet.content.ClOrdID ||
											permittedBulletTypes[
												selectedCategory
											]?.length <= 1
										}
										options={
											permittedBulletTypes[
												selectedCategory
											]?.map(type => ({
												value: type.id,
												label: type.name,
											})) ?? []
										}
										onChange={event => {
											handleBullet(
												'subUid',
												parseInt(
													event.target.value,
													10
												),
												true,
												selectedCategory
											);
										}}
									/>
								</Grid>
							)}
						</>
					)}
					{!bullet.paramsView && (
						<>
							<Grid item xs={3}>
								<Button
									fullWidth
									color="primary"
									data-cy="openButton"
									onClick={toggleBullet}
								>
									{t(toggleButtonLabel)}
								</Button>
							</Grid>

							<Grid item>
								<LightTooltip title="Abre boleta com dados da área de trabalho no formato 'Ativo,[C ou V],Quantidade,Preço'">
									<IconButton onClick={createBulletToPaste}>
										<ContentPasteGo />
									</IconButton>
								</LightTooltip>
							</Grid>
						</>
					)}
				</Grid>

				<Grid
					xs={7}
					item
					container
					spacing={1}
					sx={{ justifyContent: 'flex-end', alignItems: 'center' }}
				>
					{bullet.isOpen ? (
						<>
							{!isListsOpen && (
								<>
									<Grid item xs={3}>
										<TextField
											id="name"
											name="strategyField"
											label="Nome da Estratégia"
											fullWidth
											size="small"
											value={bullet.content.Name}
											disabled={bullet.content.ClOrdID}
											InputLabelProps={{ shrink: true }}
											inputProps={{
												style: {
													padding: 4,
													paddingLeft: 8,
												},
											}}
											onChange={event =>
												changeStrategyNameText(
													event.target.value
												)
											}
										/>
									</Grid>
									{userType.id === 5 || userType.id === 2 ? (
										<>
											<Grid item xs={3}>
												<TextField
													fullWidth
													size="small"
													label="Conta"
													name="accountTableField"
													value={bullet.account}
													disabled={
														bullet.content.ClOrdID
													}
													inputProps={{
														style: {
															padding: 4,
															paddingLeft: 8,
														},
													}}
													InputLabelProps={{
														shrink: true,
													}}
													onChange={event =>
														handleAccount(
															event.target.value
														)
													}
												/>
											</Grid>
										</>
									) : (
										<>
											<Grid item xs={3}>
												<BulletSelect
													label="Conta"
													key="accountField"
													name="accountField"
													value={
														bullet.account ??
														defaultAccount
													}
													InputLabelProps={{
														shrink: true,
													}}
													options={getAccountOptions()}
													disabled={
														bullet.content.ClOrdID
													}
													onChange={event =>
														handleAccount(
															event.target.value
														)
													}
												/>
											</Grid>

											<Grid item>
												<LightTooltip
													title="Configurações"
													arrow
												>
													<IconButton
														alignItems="right"
														color="inherit"
														onClick={() => {
															dispatch(
																setIsGlobalSettingsOpen(
																	true
																)
															);
															dispatch(
																setGlobalSettingsModule(
																	'bullet'
																)
															);
														}}
													>
														<Settings />
													</IconButton>
												</LightTooltip>
											</Grid>
										</>
									)}
								</>
							)}
						</>
					) : (
						<>
							<Grid item xs={2}>
								<Button
									fullWidth
									color="inherit"
									data-cy="recentTab"
									onClick={() => openLists(1)}
									sx={{ color: '#ffffffb3' }}
									variant={
										isRecentListVisible
											? 'outlined'
											: 'text'
									}
								>
									{t('bullets.general.recent_bullets_label')}
								</Button>
							</Grid>
							<Grid item xs={2}>
								<Button
									fullWidth
									color="inherit"
									data-cy="savedTab"
									onClick={() => openLists(2)}
									sx={{ color: '#ffffffb3' }}
									variant={
										isSavedListVisible ? 'outlined' : 'text'
									}
								>
									{t('bullets.general.saved_bullets_label')}
								</Button>
							</Grid>
						</>
					)}

					{(bullet.isOpen || isListsOpen) && (
						<Grid item>
							<LightTooltip
								title={t(
									'bullets.general.close_button_tooltip'
								)}
							>
								<IconButton
									size="small"
									color="secondary"
									aria-label="close"
									onClick={() => closeBullet(false)}
								>
									<HighlightOff fontSize="large" />
								</IconButton>
							</LightTooltip>
						</Grid>
					)}
				</Grid>
			</Grid>
		</>
	);
};
