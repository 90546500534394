import { toast } from 'react-toastify';

import { store } from 'store';
import {
	addItemBookSubscriptionList,
	addItemMiniBookSubscriptionList,
	addItemSnapshotSubscriptionList,
	addItemTradeSubscriptionList,
} from 'store/modules/websocket/actions';
import { EXM } from './bulletContext';
import {
	additionalUpdate,
	orderEntry,
	updateStrategyInfosFromApi,
} from 'store/modules/bottomBullet/actions';
import {
	getSimStrategyToAdd,
	saveStrategy,
} from 'store/modules/simulator/actions';
import { saveExternalStorage } from 'store/modules/externalStorage/actions';

import { apiAWS, apiMarketData } from 'services/api';

import { moveToSimulator } from './moveToSimulator';
import { addClipProportion } from './addClipProportion';
import addWidgetFromButton from './addWidgetFromButton';

function getSubscriptionType(type) {
	switch (type) {
		case 'T':
		case 'trade':
			return 'trade';
		case 'minibook':
			return 'minibook';
		default:
			return 'book';
	}
}

function parsingLastQuoteMiniBook(book) {
	//"PETRE239#0.23|500|0.0|0#0.0|0|0.0|0#0.0|0|0.0|0#0.0|0|0.0|0#0.0|0|0.0|0#0.0|0|0.0|0#0.0|0|0.0|0#0.0|0|0.0|0#0.0|0|0.0|0#0.0|0|0.0|0"
	let minibooks = [];
	if (book && book.length > 0) {
		let result = book.split('#');
		if (result.length > 1) {
			//PETRE239#0.23|500|0.0|0
			let symbol = result[0];
			let topOfBook = result[1].split('|');
			let fields = ['bidPx', 'bidQtty', 'askPx', 'askQtty'];
			for (let i = 0; i < topOfBook.length; i++) {
				let minibook = {
					msgType: 'M',
					symbol: symbol,
				};
				if (fields[i] === 'bidPx' || fields[i] === 'askPx') {
					minibook[fields[i]] = +topOfBook[i];
				} else {
					minibook[fields[i]] = topOfBook[i];
				}

				minibooks.push(minibook);
			}
		} else {
			console.error('Problema ao realizar split lastQuote book....');
		}
	}

	return minibooks;
}

function parsingLastQuoteBook(book) {
	const resultBook = {
		msgType: 'book',
		bidPrices: [],
		bidQuantities: [],
		askPrices: [],
		askQuantities: [],
	};
	if (book && book.length > 0) {
		let result = book.split('#');
		if (result.length > 1) {
			result.shift();

			for (let i = 0; i < 5; i++) {
				const rowResult = result[i].split('|');
				resultBook.bidPrices.push(rowResult[0]);
				resultBook.bidQuantities.push(rowResult[1]);
				resultBook.askPrices.push(rowResult[2]);
				resultBook.askQuantities.push(rowResult[3]);
			}
		} else {
			console.error('Problema ao realizar split lastQuote book....');
		}
	}

	return resultBook;
}

function parsingLastQuoteTrade(lastQuote) {
	const trade = { msgType: 'T', symbol: lastQuote.symbol };

	if ('lastPx' in lastQuote) {
		trade.price = lastQuote.lastPx;
	}

	if ('tradeVolume' in lastQuote) {
		trade.tradeVol = lastQuote.tradeVolume;
	}

	if ('financialTradeVolume' in lastQuote) {
		trade.finVol = lastQuote.financialTradeVolume;
	}

	if ('var' in lastQuote) {
		trade.var = lastQuote.var;
	}

	if ('lowPx' in lastQuote) {
		trade.lowPx = lastQuote.lowPx;
	}

	if ('highPx' in lastQuote) {
		trade.highPx = lastQuote.highPx;
	}

	if ('tradeTime' in lastQuote) {
		trade.ts = lastQuote.tradeTime;
	}

	if ('openPrice' in lastQuote) {
		trade.openPrice = lastQuote.openPrice;
	}

	if ('prevClosePx' in lastQuote) {
		trade.prevClosePx = lastQuote.prevClosePx;
	}

	if ('numberOfTrades' in lastQuote) {
		trade.tradeNbr = lastQuote.numberOfTrades;
	}

	if ('hvP' in lastQuote) {
		let percentage = +lastQuote.hvP * 100;
		trade.hv_p = percentage.toFixed(2).replace('.', ',');
	}

	if ('hvR' in lastQuote) {
		let percentage = +lastQuote.hvR * 100;
		trade.hv_rank = percentage.toFixed(2).replace('.', ',');
	}

	if ('ivP' in lastQuote) {
		let percentage = +lastQuote.ivP * 100;
		trade.iv_p = percentage.toFixed(2).replace('.', ',');
	}

	if ('ivR' in lastQuote) {
		let percentage = +lastQuote.ivR * 100;
		trade.iv_rank = percentage.toFixed(2).replace('.', ',');
	}

	if ('openInterest' in lastQuote) {
		trade.openInterest = lastQuote.openInterest;
	}

	return trade;
}

function updatePreferences(newState) {
	const payload = { ...newState };
	if (payload.instrument != {}) {
		payload.jdata = [];
		payload.portfolio = { strategies: [{ positions: [], totals: {} }] };

		delete payload.token;
		delete payload.user_name;
		delete payload.service_name;

		const username = store.getState().auth.username;

		apiAWS
			.post(
				`/userpreferences/${username}?service=options_trader`,
				payload
			)
			.then(res => {
				if (process.env.NODE_ENV === 'development')
					console.log('res', res);
			});
	}
}

function processQuoteCallback(quote, actionFunction) {
	let minibooksList = parsingLastQuoteMiniBook(quote.book);

	let trade = parsingLastQuoteTrade(quote);

	let bookList = parsingLastQuoteBook(quote.book);

	actionFunction(trade);
	actionFunction(bookList);

	for (let item of minibooksList) {
		actionFunction(item);
	}
}

async function setFirstSnapshot(key, type, actionFunction) {
	//Verifica se o symbol tem uma entrada no objeto
	if (key.includes('/OPT/')) {
		let splited_key = key.split('/');
		if (splited_key.size < 3) {
			console.error('formato nao esperado para lastQuote symbol...');
			return;
		}

		const endpoint = `/exchanges/BVMF/instruments/${splited_key[0]}/quotes/last?derivatives=${splited_key[1]}&maturityDate=${splited_key[2]}`;

		const response = await apiMarketData.get(endpoint);

		for (let quote of response.data.records) {
			processQuoteCallback(
				{ ...quote },

				actionFunction
			);
		}
	} else {
		const endpoint = `/exchanges/BVMF/instruments/${key}/quotes/last?detailed=1`;

		const response = await apiMarketData.get(endpoint);

		processQuoteCallback({ ...response.data }, actionFunction);
	}
}

export function handleSubscription(
	payload,
	registerCallback,
	unRegisterCallback
) {
	if (payload.action === 'subscribe') {
		let subscriptionType = payload.type;

		if (subscriptionType === 'snapshot') {
			store.dispatch(addItemSnapshotSubscriptionList(subscriptionType));
		} else {
			if (subscriptionType === 'book') {
				store.dispatch(addItemBookSubscriptionList(payload.key));
			} else if (subscriptionType === 'trade') {
				store.dispatch(addItemTradeSubscriptionList(payload.key));
			} else if (subscriptionType === 'minibook') {
				store.dispatch(addItemMiniBookSubscriptionList(payload.key));
			}

			setFirstSnapshot(
				payload.key,
				subscriptionType,
				payload.callbackFunction
			);
		}

		const uuid = registerCallback({ ...payload, type: subscriptionType });
		return uuid;
	} else if (payload.action === 'unsubscribe') {
		unRegisterCallback(payload.id);
	}
}

export function actionListener(
	action,
	payload,
	history,
	registerCallback,
	unRegisterCallback
) {
	if (action === 'mkt_subscribe' || action === 'mkt_unsubscribe') {
		// definindo a action
		payload.action =
			action === 'mkt_subscribe' ? 'subscribe' : 'unsubscribe';

		if (payload.action === 'subscribe') {
			//verificando se o valor passado a ser subscrito e valido
			if (payload.key || payload.topic) {
				//Definindo tipo de subscricao
				payload.type = getSubscriptionType(payload.type);

				//definindo chave (symbol ou topico) a ser subscrito
				payload.key = payload.key ? payload.key : payload.topic;

				//definindo callback a ser chamado
				payload.callbackFunction = payload.callbackFunction
					? payload.callbackFunction
					: payload.callbackFunc;
			} else {
				//aborta request
				return;
			}
		}

		return handleSubscription(
			payload,
			registerCallback,
			unRegisterCallback
		);
	} else if (action === 'add_widget') {
		// Abrir book a partir da options trader
		if (payload.type === 'BookTrade') {
			addWidgetFromButton(payload.symbol);
		}
	} else if (action === 'order_entry') {
		const permittedStrategyCodes =
			store.getState().products.permittedBullets.permittedStrategyCodes;

		if (!permittedStrategyCodes.includes(payload.StrategyCode)) {
			toast.error(
				'Esta estratégia não é permitida para suas assinaturas atuais.'
			);
			return;
		}

		addClipProportion(payload.StrategyLegs, payload.StrategyInfos);

		// preencher boleta
		payload.context = EXM;
		store.dispatch(orderEntry(payload, payload?.subUid));
		store.dispatch(updateStrategyInfosFromApi(payload.StrategyLegs));

		// abrir boleta
		store.dispatch(additionalUpdate('isOpen', true));
		store.dispatch(additionalUpdate('isRecentListVisible', false));
		store.dispatch(additionalUpdate('isSavedListVisible', false));

		// solicitar o lastPx para cada perna via api /Quotelast
	} else if (action === 'state_change') {
		// Alteração de status
		payload.state.recreateFromState = false;
		updatePreferences(payload.state);
		store.dispatch(saveExternalStorage(payload, 'optionsChain')); //substituir optionsChain por payload.id
	} else if (action === 'add_sim_strategy') {
		if (!payload?.strategy) return; //Se não tiver objeto strategy aborta

		const { username, account } = store.getState().auth;

		const data = getSimStrategyToAdd(payload.strategy, username, account);

		if (data) {
			//Will return null if no legs are added
			store.dispatch(data); //Add strategy in simulator

			//Save strategy
			if (payload?.toSave === true) {
				store.dispatch(saveStrategy(data.payload.strategy));
			}

			// Move user to simulator
			if (payload?.navToSimulator === true) {
				moveToSimulator(history, data.payload.strategy);
			}
		}
	}
}
